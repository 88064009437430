$(() => {
  if (
    $("body").hasClass("properties") &&
    ($("body").hasClass("new") || $("body").hasClass("edit-infos"))
  ) {
    const $form = $("form.edit-infos");

    const $submitBtn = $form.find("[type=submit]");

    $form.validate({
      ignoreTitle: true,
      rules: {},
      messages: {},
      errorElement: "label",
      errorPlacement: (error, element) => {
        var fg = $(element).closest(".form-group");
        $(fg).addClass("has-error");
        $(fg).append(error);
      },
      success: (label, element) => {
        var fg = $(element).closest(".form-group");
        $(fg).removeClass("has-error");
      },
      submitHandler: (form) => {
        $submitBtn.addClass("loading").attr("disabled", true);
        form.submit();
      },
      invalidHandler: (event, validator) => {
        $submitBtn.removeClass("loading").attr("disabled", false);
      },
    });

    // Delivery
    const $deliverySelect = $form.find(".select2#property_delivery_tmp");
    const deliverySelect2 = $deliverySelect
      .select2({
        minimumResultsForSearch: Infinity,
        placeholder: "Choisir",
        allowClear: true,
      })
      .on("select2:unselecting", (event) => {
        $(event.currentTarget).data("unselecting", true);
      })
      .on("select2:opening", (event) => {
        const $this = $(event.currentTarget);
        if ($this.data("unselecting")) {
          $this.removeData("unselecting");
          event.preventDefault();
        }
      });

    deliverySelect2
      .data("select2")
      .$container.find(".select2-selection")
      .removeClass("select2-selection--single")
      .addClass("custom-select");

    $deliverySelect.on("change", (event) => {
      const $this = $(event.currentTarget);
      $this.closest(".form-group").addClass("has-error");
      $this.valid();
    });

    // Prop Type
    var $propTypeSelect = $form.find(".select2[name='property[prop_type]']");
    const propTypeSelect2 = $propTypeSelect
      .select2({
        minimumResultsForSearch: Infinity,
        placeholder: "Choisir",
        allowClear: true,
      })
      .on("select2:unselecting", (event) => {
        $(event.currentTarget).data("unselecting", true);
      })
      .on("select2:opening", (event) => {
        const $this = $(event.currentTarget);
        if ($this.data("unselecting")) {
          $this.removeData("unselecting");
          event.preventDefault();
        }
      });

    propTypeSelect2
      .data("select2")
      .$container.find(".select2-selection")
      .removeClass("select2-selection--single")
      .addClass("custom-select");

    $propTypeSelect.on("change", (event) => {
      const $this = $(event.currentTarget);
      $this.closest(".form-group").addClass("has-error");
      $this.valid();
    });

    // Floor
    let floor = new Cleave("#property_floor", {
      numeral: true,
      numeralDecimalMark: "",
      delimiter: "",
      numeralPositiveOnly: true,
    });

    // Living Area
    let livingArea = new Cleave("#property_living_area", {
      numeral: true,
      numeralDecimalMark: ",",
      delimiter: "",
      numeralPositiveOnly: true,
    });

    // Num rooms
    let numRooms = new Cleave("#property_num_rooms", {
      numeral: true,
      numeralDecimalMark: "",
      delimiter: "",
      numeralPositiveOnly: true,
    });

    // Num bedrooms
    let numBedrooms = new Cleave("#property_num_bedrooms", {
      numeral: true,
      numeralDecimalMark: "",
      delimiter: "",
      numeralPositiveOnly: true,
    });

    // Num bathrooms
    let numBathrooms = new Cleave("#property_num_bathrooms", {
      numeral: true,
      numeralDecimalMark: "",
      delimiter: "",
      numeralPositiveOnly: true,
    });

    // Num Water Rooms
    let numWatrooms = new Cleave("#property_num_watrooms", {
      numeral: true,
      numeralDecimalMark: "",
      delimiter: "",
      numeralPositiveOnly: true,
    });

    // Num WCs
    let numWCs = new Cleave("#property_num_wcs", {
      numeral: true,
      numeralDecimalMark: "",
      delimiter: "",
      numeralPositiveOnly: true,
    });

    // Num sep WCs
    let numSepWCs = new Cleave("#property_num_sep_wcs", {
      numeral: true,
      numeralDecimalMark: "",
      delimiter: "",
      numeralPositiveOnly: true,
    });

    // Num parkings
    let numParkings = new Cleave("#property_num_parkings", {
      numeral: true,
      numeralDecimalMark: "",
      delimiter: "",
      numeralPositiveOnly: true,
    });

    // Num Cellars
    let numCellars = new Cleave("#property_num_cellars", {
      numeral: true,
      numeralDecimalMark: "",
      delimiter: "",
      numeralPositiveOnly: true,
    });

    // Garden Area
    let gardenArea = new Cleave("#property_garden_area", {
      numeral: true,
      numeralDecimalMark: ",",
      delimiter: "",
      numeralPositiveOnly: true,
    });

    // Terrace Area
    let terraceArea = new Cleave("#property_terrace_area", {
      numeral: true,
      numeralDecimalMark: ",",
      delimiter: "",
      numeralPositiveOnly: true,
    });

    // Balcony Area
    let balconyArea = new Cleave("#property_balcony_area", {
      numeral: true,
      numeralDecimalMark: ",",
      delimiter: "",
      numeralPositiveOnly: true,
    });

    // Loggia Area
    let loggiaArea = new Cleave("#property_loggia_area", {
      numeral: true,
      numeralDecimalMark: ",",
      delimiter: "",
      numeralPositiveOnly: true,
    });

    // Price FAI TTC
    let price = new Cleave("#property_price", {
      numeral: true,
      numeralDecimalMark: "",
      delimiter: " ",
      numeralPositiveOnly: true,
    });

    // Fees Amount
    let feesAmount = new Cleave("#property_fees_amount", {
      numeral: true,
      numeralDecimalMark: "",
      delimiter: " ",
      numeralPositiveOnly: true,
    });

    // Fees rate
    let feesRate = new Cleave("#property_fees_rate", {
      numeral: true,
      numeralDecimalMark: ",",
      delimiter: "",
      numeralPositiveOnly: true,
    });

    if (fiscality == "fpinel") {
      // 1 - Weighted Area
      let weightedArea = new Cleave("#property_weighted_area", {
        numeral: true,
        numeralDecimalMark: ",",
        delimiter: "",
        numeralPositiveOnly: true,
      });

      const $weightedAreaModal = $(".modal#weighted-area-modal");
      let weightedAreaTmpValue = 0;

      $weightedAreaModal.find(".btn.applyValue").on("click", (e) => {
        weightedArea.setRawValue(weightedAreaTmpValue);
        $weightedAreaModal.modal("hide");
      });

      $(".evalWeightedArea").on("click", (e) => {
        e.preventDefault();

        if (livingArea.getRawValue() === "") {
          bootbox.alert({
            className: "",
            centerVertical: true,
            backdrop: true,
            message: `<div class="py-2">Veuillez renseigner la surface habitable !</div>`,
            closeButton: false,
            buttons: {
              ok: {
                label: "OK",
                className: "btn-secondary px-5",
              },
            },
          });
          return;
        }

        const data = {
          living_area: parseFloat(livingArea.getRawValue() || 0),
          annexes_area:
            parseFloat(terraceArea.getRawValue() || 0) +
            parseFloat(balconyArea.getRawValue() || 0) +
            parseFloat(loggiaArea.getRawValue() || 0),
        };

        $.ajax({
          url: evalWeightedAreaURL,
          type: "POST",
          data: { data },
          dataType: "json",
          success: (data, status, xhr) => {
            $weightedAreaModal
              .find("td.living-area")
              .html(data.living_area + "m<sup>2</sup>");
            $weightedAreaModal
              .find("td.annexes-area")
              .html(data.annexes_area + "m<sup>2</sup>");
            $weightedAreaModal
              .find("td.weighted-area")
              .html(data.weighted_area + "m<sup>2</sup>");
            weightedAreaTmpValue = parseFloat(data.weighted_area);
            $weightedAreaModal.modal("show");
          },
          error: (res) => {
            console.log(res.responseJSON.errors);
          },
        });
      });

      // 2 - Pinel Rent
      let pinelRent = new Cleave("#property_pinel_rent", {
        numeral: true,
        numeralDecimalMark: ",",
        delimiter: "",
        numeralPositiveOnly: true,
      });

      const $pinelRentModal = $(".modal#pinel-rent-modal");
      let pinelRentTmpValue = 0;

      $pinelRentModal.find(".btn.applyValue").on("click", (e) => {
        pinelRent.setRawValue(pinelRentTmpValue);
        $pinelRentModal.modal("hide");
      });

      $(".evalPinelRent").on("click", (e) => {
        e.preventDefault();

        if (weightedArea.getRawValue() === "") {
          bootbox.alert({
            className: "",
            centerVertical: true,
            backdrop: true,
            message: `<div class="py-2">Veuillez renseigner la surface pondérée !</div>`,
            closeButton: false,
            buttons: {
              ok: {
                label: "OK",
                className: "btn-secondary px-5",
              },
            },
          });
          return;
        }

        const data = {
          weighted_area: parseFloat(weightedArea.getRawValue() || 0),
          pinel_zone: pinelZone,
        };

        $.ajax({
          url: evalPinelRentURL,
          type: "POST",
          data: { data },
          dataType: "json",
          success: (data, status, xhr) => {
            $pinelRentModal
              .find("td.weighted-area")
              .html(data.weighted_area + "m<sup>2</sup>");
            $pinelRentModal.find("td.pinel-zone").html(data.pinel_zone);
            $pinelRentModal
              .find("td.pinel-ceiling")
              .html(data.pinel_ceiling + "€/m<sup>2</sup>");
            $pinelRentModal
              .find("td.pinel-coefficient")
              .html(data.pinel_coefficient);
            $pinelRentModal.find("td.pinel-rent").html(data.pinel_rent + "€");

            pinelRentTmpValue = parseFloat(data.pinel_rent);
            $pinelRentModal.modal("show");
          },
          error: (res) => {
            console.log(res.responseJSON.errors);
          },
        });
      });
    }

    if (fiscality == "flmnp") {
      // Price HT
      let priceHT = new Cleave("#property_price_ht", {
        numeral: true,
        numeralDecimalMark: "",
        delimiter: " ",
        numeralPositiveOnly: true,
      });

      // Rent HT
      let rentHT = new Cleave("#property_lmnp_rent_ht", {
        numeral: true,
        numeralDecimalMark: "",
        delimiter: " ",
        numeralPositiveOnly: true,
      });

      // Return HT
      let returnHT = new Cleave("#property_lmnp_return_ht", {
        numeral: true,
        numeralDecimalMark: ",",
        delimiter: "",
        numeralPositiveOnly: true,
      });
    }

    // Show form
    $form.removeClass("d-none");
  }
});
