$(() => {
  if (
    $("body").hasClass("faq") &&
    $("body").hasClass("questions") &&
    ($("body").hasClass("new") || $("body").hasClass("edit"))
  ) {
    const $form = $("form#edit-faq-question");
    const $submitBtn = $form.find("[type=submit]");

    $form.validate({
      ignoreTitle: true,
      rules: {
        "faq_question[title]": { required: true },
        "faq_question[content]": { required: true },
      },
      messages: {
        "faq_question[title]": {
          required: "Titre obligatoire",
        },
        "faq_question[content]": {
          required: "Texte obligatoire",
        },
      },
      errorElement: "label",
      errorPlacement: (error, element) => {
        var fg = $(element).closest(".form-group");
        $(fg).addClass("has-error");
        $(fg).append(error);
      },
      success: (label, element) => {
        var fg = $(element).closest(".form-group");
        $(fg).removeClass("has-error");
      },
      submitHandler: (form) => {
        $submitBtn.addClass("loading").attr("disabled", true);
        form.submit();
      },
      invalidHandler: (event, validator) => {
        $submitBtn.removeClass("loading").attr("disabled", false);
      },
    });

    // Status select
    const $statusSelect = $form.find(".select2#faq_question_status");
    const statusSelect2 = $statusSelect
      .select2({
        minimumResultsForSearch: Infinity,
        placeholder: "Choisir",
        allowClear: true,
      })
      .on("select2:unselecting", (event) => {
        $(event.currentTarget).data("unselecting", true);
      })
      .on("select2:opening", (event) => {
        const $this = $(event.currentTarget);
        if ($this.data("unselecting")) {
          $this.removeData("unselecting");
          event.preventDefault();
        }
      });

    statusSelect2
      .data("select2")
      .$container.find(".select2-selection")
      .removeClass("select2-selection--single")
      .addClass("custom-select");

    $statusSelect.on("change", (event) => {
      const $this = $(event.currentTarget);
      $this.closest(".form-group").addClass("has-error");
      $this.valid();
    });

    // Show form
    $form.removeClass("d-none");
  }
});
