import Sortable from "sortablejs/Sortable";

$(() => {
  if (
    $("body").hasClass("faq") &&
    $("body").hasClass("questions") &&
    $("body").hasClass("index")
  ) {
    const updatePositions = () => {
      let data = [];
      const $questions = $("#category-questions tr");

      $.each($questions, (index, elem) => {
        data.push({ pid: $(elem).data("pid"), position: index + 1 });
      });

      $.ajax({
        url: updatePositionsURL,
        type: "PUT",
        data: { data: data },
        dataType: "json",
        success: (data, status, xhr) => {
          toastr.success("Positions sauvegardées");
        },
        error: (res) => {
          toastr.error("Une erreur s'est produite");
        },
      });
    };

    // Sort
    new Sortable(document.getElementById("category-questions"), {
      animation: 150,
      handle: ".indexCell",
      onUpdate: (event) => {
        updatePositions();
      },
    });
  }
});
