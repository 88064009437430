$(() => {
  if ($("body").hasClass("notifications") && $("body").hasClass("index")) {
    const $notifStatus = $(".nav-notifications .btn-status");
    const $resultsBody = $(".results-body");

    const updateNotificationsBadge = (num) => {
      if (num > 0) {
        $notifStatus.show();
      } else {
        $notifStatus.hide();
      }
    };

    const cleanResultsBody = () => {
      const numNotifs = $(".notification-wrapper").length;
      if (numNotifs == 0) {
        $resultsBody.remove();
      }
    };

    $(".notification-wrapper .btn-delete").on("click", (e) => {
      e.preventDefault();
      const $wrapper = $(e.currentTarget).closest(".notification-wrapper");
      const notificationId = $wrapper.data("notification-id");

      $.ajax({
        url: "/notifications/" + notificationId,
        method: "DELETE",
        dataType: "json",
        success: (data, status, xhr) => {
          $wrapper.slideUp(200, () => {
            $wrapper.remove();
            updateNotificationsBadge(data.unread);
            cleanResultsBody();
          });
        },
        error: () => {
          toastr.error("Une erreur s'est produite");
        },
      });
    });
  }
});
