$(() => {
  if ($("body").hasClass("buy-leads") && $("body").hasClass("index")) {
    const $filtersForm = $("form#search-filters");
    const $searchStatus = $filtersForm.find('[name="search[status]"]');

    $searchStatus.on("change", (e) => {
      $(".results-wrapper").addClass("loading");
      const status = $('[name="search[status]"]:checked').val();

      const data = {
        status,
      };

      let url = buyLeadsIndexURL;
      if (status !== "all") {
        const hash = $.param(data);
        url = url + "?" + hash;
      }

      window.location = url;
    });
  }
});
