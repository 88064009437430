import lightGallery from "lightgallery";
import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";

$(() => {
  if ($("body").hasClass("developments") && $("body").hasClass("show")) {
    lightGallery(document.getElementById("lg-images"), {
      plugins: [lgZoom, lgThumbnail],
      mode: "lg-slide",
      speed: 500,
      loop: false,
      slideEndAnimation: false,
      licenseKey: "3313B03C-32E04EB5-829DA18A-AED95D87",
    });

    const openImagesGallery = () => {
      $("#lg-images img:first").trigger("click");
    };

    $(".gallery-wrapper .card-cover").on("click", (event) => {
      event.preventDefault();
      openImagesGallery();
    });
  }
});
