$(() => {
  if ($("body").hasClass("properties") && $("body").hasClass("index")) {
    const $filtersForm = $("form#search-filters");

    // Places
    const $searchPlacesWrapper = $(".search-places-wrapper");
    const $searchPlacesSelect = $searchPlacesWrapper.find(".select2");
    const searchPlacesSelect2 = $searchPlacesSelect
      .select2({
        minimumInputLength: 2,
        placeholder: "Ville, département, région...",
        dropdownParent: $searchPlacesWrapper,
        allowClear: true,
        ajax: {
          url: searchPlacesURL,
          delay: 250,
          data: (params) => {
            const query = {
              text: params.term,
            };
            return query;
          },
          processResults: (data) => {
            const hits = data.hits;
            let res = [];

            $.each(hits, (key, values) => {
              let tmp = { text: key, children: [] };
              $.each(values, (index, val) => {
                tmp["children"].push({ id: val["pid"], text: val["name"] });
              });
              res.push(tmp);
            });

            return {
              results: res,
            };
          },
        },
      })
      .on("select2:unselecting", (event) => {
        $(event.currentTarget).data("unselecting", true);
      })
      .on("select2:opening", (event) => {
        const $this = $(event.currentTarget);
        if ($this.data("unselecting")) {
          $this.removeData("unselecting");
          event.preventDefault();
        }
      });

    searchPlacesSelect2
      .data("select2")
      .$container.find(".select2-selection")
      .removeClass("select2-selection--single")
      .addClass("custom-select");

    // Delivery
    const $searchDeliveryWrapper = $(".search-delivery-wrapper");
    const $searchDeliverySelect = $searchDeliveryWrapper.find(".select2");
    const searchDeliverySelect2 = $searchDeliverySelect
      .select2({
        minimumResultsForSearch: Infinity,
        dropdownParent: $searchDeliveryWrapper,
        placeholder: "Choisir",
        allowClear: true,
      })
      .on("select2:unselecting", (event) => {
        $(event.currentTarget).data("unselecting", true);
      })
      .on("select2:opening", (event) => {
        const $this = $(event.currentTarget);
        if ($this.data("unselecting")) {
          $this.removeData("unselecting");
          event.preventDefault();
        }
      });

    searchDeliverySelect2
      .data("select2")
      .$container.find(".select2-selection")
      .removeClass("select2-selection--single")
      .addClass("custom-select");

    // Fiscality
    const $searchFiscalityWrapper = $(".search-fiscality-wrapper");
    const $searchFiscalitySelect = $searchFiscalityWrapper.find(".select2");
    const searchFiscalitySelect2 = $searchFiscalitySelect
      .select2({
        minimumResultsForSearch: Infinity,
        dropdownParent: $searchFiscalityWrapper,
        placeholder: "Choisir",
        allowClear: true,
      })
      .on("select2:unselecting", (event) => {
        $(event.currentTarget).data("unselecting", true);
      })
      .on("select2:opening", (event) => {
        const $this = $(event.currentTarget);
        if ($this.data("unselecting")) {
          $this.removeData("unselecting");
          event.preventDefault();
        }
      });

    searchFiscalitySelect2
      .data("select2")
      .$container.find(".select2-selection")
      .removeClass("select2-selection--single")
      .addClass("custom-select");

    // Min Area
    let minAreaInput = new Cleave("#search_min_area", {
      numeral: true,
      numeralDecimalMark: "",
      delimiter: " ",
      numeralPositiveOnly: true,
    });

    // Max Price
    let maxPriceInput = new Cleave("#search_max_price", {
      numeral: true,
      numeralDecimalMark: "",
      delimiter: " ",
      numeralPositiveOnly: true,
    });

    const $sortOrderSelect = $(".sort-order-wrapper .select2");
    if ($sortOrderSelect.length) {
      const sortOrderSelect2 = $sortOrderSelect.select2({
        minimumResultsForSearch: Infinity,
      });

      sortOrderSelect2
        .data("select2")
        .$container.find(".select2-selection")
        .removeClass("select2-selection--single")
        .addClass("custom-select");

      $(".sort-order-wrapper").addClass("d-flex").removeClass("d-none");
    }

    const evalFilters = () => {
      const filters = {};

      const placePID = $searchPlacesSelect.val();
      if (placePID) {
        filters["place_pid"] = placePID;
      }

      const propTypes = [];
      $filtersForm.find('[name="prop_types[]"]:checked').each(function () {
        propTypes.push($(this).val());
      });
      if (propTypes.length) {
        filters["prop_types"] = propTypes;
      }

      const numRooms = [];
      $filtersForm.find('[name="num_rooms[]"]:checked').each(function () {
        numRooms.push($(this).val());
      });
      if (numRooms.length) {
        filters["num_rooms"] = numRooms;
      }

      const minArea = minAreaInput.getRawValue();
      if (minArea) {
        filters["min_area"] = minArea;
      }

      const maxPrice = maxPriceInput.getRawValue();
      if (maxPrice) {
        filters["max_price"] = maxPrice;
      }

      const deliveryYear = $searchDeliverySelect.val();
      if (deliveryYear) {
        filters["delivery_year"] = deliveryYear;
      }

      const fiscality = $searchFiscalitySelect.val();
      if (fiscality) {
        filters["fiscality"] = fiscality;
      }

      const annexes = [];
      $filtersForm.find('[name="annexes[]"]:checked').each(function () {
        annexes.push($(this).val());
      });
      if (annexes.length) {
        filters["annexes"] = annexes;
      }

      return filters;
    };

    $filtersForm.on("submit", (e) => {
      e.preventDefault();

      $(".results-wrapper").addClass("loading");
      const filters = evalFilters();

      let url = propertiesIndexURL;
      if (!$.isEmptyObject(filters)) {
        let queryParams = $.param(filters);
        url = url + "?" + queryParams;
      }
      window.location = url;
    });

    if ($sortOrderSelect.length) {
      $sortOrderSelect.on("change", (e) => {
        e.preventDefault();

        $(".results-wrapper").addClass("loading");
        const filters = evalFilters();

        const order = $sortOrderSelect.val();
        if (order != "created_desc") {
          filters["sort"] = order;
        }

        let url = propertiesIndexURL;
        if (!$.isEmptyObject(filters)) {
          let queryParams = $.param(filters);
          url = url + "?" + queryParams;
        }
        window.location = url;
      });
    }
  }
});
