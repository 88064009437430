$(() => {
  if ($("body").hasClass("developments") && $("body").hasClass("index")) {
    const $searchDevelopmentsWrapper = $(".search-developments-wrapper");
    const $searchDevelopmentsSelect = $searchDevelopmentsWrapper.find(
      "select#search-developments-select"
    );
    const $sortOrderSelect = $(".sort-order-wrapper .select2");

    const buildDevelopmentSearchForm = () => {
      if ($searchDevelopmentsSelect.length) {
        const developmentsSelect2 = $searchDevelopmentsSelect.select2({
          minimumInputLength: 2,
          placeholder: "Nom du programme",
          dropdownParent: $searchDevelopmentsWrapper,
          ajax: {
            url: searchDevelopmentsURL,
            delay: 250,
            data: (params) => {
              const query = {
                text: params.term,
              };
              return query;
            },
            processResults: (data) => {
              const hits = data.hits;
              let res = [];

              $.each(hits, function (key, value) {
                res.push({ id: value["pid"], text: value["name"] });
              });

              return {
                results: res,
              };
            },
          },
        });

        developmentsSelect2
          .data("select2")
          .$container.find(".select2-selection")
          .removeClass("select2-selection--single")
          .addClass("custom-select rounded-0 rounded-end");

        $searchDevelopmentsSelect.on("change", (event) => {
          const pid = $(event.currentTarget).val();
          window.location = `${showDevelopmentURL}${pid}`;
        });
      }

      // Show form
      $searchDevelopmentsWrapper.removeClass("d-none");
    };

    const buildSortDevelopmentsForm = () => {
      if ($sortOrderSelect.length) {
        const sortOrderSelect2 = $sortOrderSelect.select2({
          minimumResultsForSearch: Infinity,
        });

        sortOrderSelect2
          .data("select2")
          .$container.find(".select2-selection")
          .removeClass("select2-selection--single")
          .addClass("custom-select");

        $sortOrderSelect.on("change", function (e) {
          e.preventDefault();
          let filters = {};
          const order = $sortOrderSelect.val();

          if (order != "created_desc") {
            filters["sort"] = $sortOrderSelect.val();
          }

          let url = developmentsIndexURL;
          const ct = Object.keys(filters).length;
          if (ct > 0) {
            const hash = $.param(filters);
            url = url + "?" + hash;
          }
          window.location = url;
        });

        $(".sort-order-wrapper").addClass("d-flex").removeClass("d-none");
      }
    };

    buildDevelopmentSearchForm();
    buildSortDevelopmentsForm();
  }
});
