Trix.config.blockAttributes.heading2 = {
  tagName: "h2",
  terminal: true,
  breakOnReturn: true,
  group: false,
};

Trix.config.blockAttributes.heading3 = {
  tagName: "h3",
  terminal: true,
  breakOnReturn: true,
  group: false,
};

addEventListener("trix-initialize", function (event) {
  var toolbarElement = event.target.toolbarElement;
  var h1Button = toolbarElement.querySelector("[data-trix-attribute=heading1]");
  h1Button.insertAdjacentHTML(
    "afterend",
    '<button type="button" class="trix-button" data-trix-attribute="heading3" title="Heading 3" tabindex="-1" data-trix-active="">H3</button>'
  );
  h1Button.insertAdjacentHTML(
    "afterend",
    '<button type="button" class="trix-button" data-trix-attribute="heading2" title="Heading 2" tabindex="-1" data-trix-active="">H2</button>'
  );
});
