/*
 * Hacky fix for a bug in select2 with jQuery 3.6.0's new nested-focus "protection"
 * see: https://github.com/select2/select2/issues/5993
 * see: https://github.com/jquery/jquery/issues/4382
 */

$(document).on("select2:open", (event) => {
  const searchField = document.querySelector(
    `.select2-search__field[aria-controls="select2-${event.target.getAttribute(
      "data-select2-id"
    )}-results"]`
  );
  if (searchField) {
    searchField.focus();
  }
});
