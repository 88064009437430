import { prettyFileSize } from "../../../../lib/file";
import ActiveStorageUpload from "@excid3/uppy-activestorage-upload";
import Uppy from "@uppy/core";
import Dashboard from "@uppy/dashboard";
import Sortable from "sortablejs/Sortable";

$(() => {
  if ($("body").hasClass("developments") && $("body").hasClass("edit-images")) {
    const $form = $("form#edit-images");
    const $imgGallery = $("#development-images");
    const $submitBtn = $form.find("[type=submit]");

    $submitBtn.on("click", (event) => {
      $(event.currentTarget).addClass("loading");
    });

    const buildIndexes = () => {
      $("#development-images .image-wrapper").each((index, elem) => {
        const newIndex = index + 1;
        $(elem)
          .find(".keyInput")
          .attr("name", "images[" + newIndex + "][key]");
      });
    };

    const buildEvents = () => {
      $("#development-images .removeImg").off("click");
      $("#development-images .removeImg").on("click", (event) => {
        if (confirm("Supprimer cette image?")) {
          $(event.currentTarget).closest(".image-wrapper").remove();
          buildIndexes();
        }
      });
    };

    const addUploadedFile = (file) => {
      const index = $imgGallery.find(".image-wrapper").length + 1;

      const tpl = `
        <div class="image-wrapper col-12 col-md-6 col-lg-4 image-sort">
          <div class="card">
            <div class="card-cover">
              <img src="${cloudFrontURL}/${file.response.key}" />
            </div>
            <div class="card-body">
              <table class="details">
                <tbody>
                  <tr>
                    <td>Type</td>
                    <td>${file.response.content_type}</td>
                  </tr>
                  <tr>
                    <td>Taille</td>
                    <td>${prettyFileSize(file.response.byte_size)}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="card-footer">
              <a href="javascript:;" class="text-danger no-underline removeImg">Supprimer</a>
            </div>
          </div>
          <input type="hidden" name="images[${index}][key]" class="keyInput" value="${
        file.response.key
      }">
        </div>
      `;

      $imgGallery.append(tpl);
    };

    // Sort
    var sortable = new Sortable(document.getElementById("development-images"), {
      animation: 150,
      onUpdate: (event) => {
        buildIndexes();
      },
    });

    const setUpUppy = () => {
      let trigger = document.querySelector("[data-behavior='uppy-trigger']");
      let directUploadUrl = $("meta[name='direct-upload-url']").attr("content");

      trigger.addEventListener("click", (e) => {
        e.preventDefault();
      });

      let uppy = Uppy({
        autoProceed: true,
        allowMultipleUploads: true,
        logger: Uppy.debugLogger,
      });

      uppy.use(ActiveStorageUpload, {
        directUploadUrl: directUploadUrl,
      });

      uppy.use(Dashboard, {
        trigger: trigger,
        closeAfterFinish: false,
      });

      uppy.on("complete", (result) => {
        result.successful.forEach((file) => {
          console.log(file);
          addUploadedFile(file);
          buildIndexes();
          buildEvents();
        });
      });
    };

    buildIndexes();
    buildEvents();
    setUpUppy();
  }
});
