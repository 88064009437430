$(() => {
  if (
    $("body").hasClass("blog") &&
    $("body").hasClass("categories") &&
    ($("body").hasClass("new") || $("body").hasClass("edit"))
  ) {
    const $form = $("form#edit-blog-category");
    const $submitBtn = $form.find("[type=submit]");

    $form.validate({
      ignoreTitle: true,
      rules: {},
      messages: {},
      errorElement: "label",
      errorPlacement: (error, element) => {
        var fg = $(element).closest(".form-group");
        $(fg).addClass("has-error");
        $(fg).append(error);
      },
      success: (label, element) => {
        var fg = $(element).closest(".form-group");
        $(fg).removeClass("has-error");
      },
      submitHandler: (form) => {
        $submitBtn.addClass("loading").attr("disabled", true);
        form.submit();
      },
      invalidHandler: (event, validator) => {
        $submitBtn.removeClass("loading").attr("disabled", false);
      },
    });

    // Show form
    $form.removeClass("d-none");
  }
});
