import ActiveStorageUpload from "@excid3/uppy-activestorage-upload";
import Uppy from "@uppy/core";
import Dashboard from "@uppy/dashboard";

$(() => {
  if (
    $("body").hasClass("blog") &&
    $("body").hasClass("articles") &&
    ($("body").hasClass("new") || $("body").hasClass("edit"))
  ) {
    const $form = $("form#edit-blog-article");
    const $submitBtn = $form.find("[type=submit]");

    $form.validate({
      ignoreTitle: true,
      rules: {
        "blog_article[title]": { required: true },
        "blog_article[content]": { required: true },
      },
      messages: {
        "blog_article[title]": {
          required: "Titre obligatoire",
        },
        "blog_article[content]": {
          required: "Texte obligatoire",
        },
      },
      errorElement: "label",
      errorPlacement: (error, element) => {
        var fg = $(element).closest(".form-group");
        $(fg).addClass("has-error");
        $(fg).append(error);
      },
      success: (label, element) => {
        var fg = $(element).closest(".form-group");
        $(fg).removeClass("has-error");
      },
      submitHandler: (form) => {
        $submitBtn.addClass("loading").attr("disabled", true);
        form.submit();
      },
      invalidHandler: (event, validator) => {
        $submitBtn.removeClass("loading").attr("disabled", false);
      },
    });

    // Category select
    const $blogCategorySelect = $form.find(
      ".select2#blog_article_blog_category_id"
    );
    const blogCategorySelect2 = $blogCategorySelect
      .select2({
        minimumResultsForSearch: Infinity,
        placeholder: "Choisir",
        allowClear: true,
      })
      .on("select2:unselecting", (event) => {
        $(event.currentTarget).data("unselecting", true);
      })
      .on("select2:opening", (event) => {
        const $this = $(event.currentTarget);
        if ($this.data("unselecting")) {
          $this.removeData("unselecting");
          event.preventDefault();
        }
      });

    blogCategorySelect2
      .data("select2")
      .$container.find(".select2-selection")
      .removeClass("select2-selection--single")
      .addClass("custom-select");

    $blogCategorySelect.on("change", (event) => {
      const $this = $(event.currentTarget);
      $this.closest(".form-group").addClass("has-error");
      $this.valid();
    });

    // Status select
    const $statusSelect = $form.find(".select2#blog_article_status");
    const statusSelect2 = $statusSelect
      .select2({
        minimumResultsForSearch: Infinity,
        placeholder: "Choisir",
        allowClear: true,
      })
      .on("select2:unselecting", (event) => {
        $(event.currentTarget).data("unselecting", true);
      })
      .on("select2:opening", (event) => {
        const $this = $(event.currentTarget);
        if ($this.data("unselecting")) {
          $this.removeData("unselecting");
          event.preventDefault();
        }
      });

    statusSelect2
      .data("select2")
      .$container.find(".select2-selection")
      .removeClass("select2-selection--single")
      .addClass("custom-select");

    $statusSelect.on("change", (event) => {
      const $this = $(event.currentTarget);
      $this.closest(".form-group").addClass("has-error");
      $this.valid();
    });

    // Upload logo
    const $uppyWrapper = $("[data-uppy]");

    const setUpUppy = () => {
      let trigger = document.querySelector("[data-behavior='uppy-trigger']");
      let directUploadUrl = $("meta[name='direct-upload-url']").attr("content");
      let fieldName = $uppyWrapper.data("uppy");

      trigger.addEventListener("click", (e) => {
        e.preventDefault();
      });

      let uppy = Uppy({
        autoProceed: true,
        allowMultipleUploads: false,
        logger: Uppy.debugLogger,
      });

      uppy.use(ActiveStorageUpload, {
        directUploadUrl: directUploadUrl,
      });

      uppy.use(Dashboard, {
        trigger: trigger,
        closeAfterFinish: false,
      });

      uppy.on("complete", (result) => {
        $("[data-pending-upload]").remove();

        result.successful.forEach((file) => {
          setUploadedFile(file, fieldName);
        });
      });
    };

    const setUploadedFile = (file, fieldName) => {
      $uppyWrapper
        .find(".coverImg")
        .attr("src", `${cloudFrontURL}/${file.response.key}`);
      $uppyWrapper.find(".coverKey").val(file.response.key);
    };

    setUpUppy();

    // Show form
    $form.removeClass("d-none");
  }
});
