$(() => {
  if ($("body").hasClass("properties") && $("body").hasClass("alerts")) {
    const $form = $("form#send-alerts");
    const $formSubmitBtn = $form.find("button[type=submit]");

    $form.on("submit", (e) => {
      $formSubmitBtn.addClass("loading").attr("disabled", true);
    });
  }
});
