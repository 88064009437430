// Rails
require("@rails/ujs").start();
require("@rails/activestorage").start();
require("@rails/actiontext");

// Bootstrap
require("bootstrap/dist/js/bootstrap");

// JQuery Validation
require("jquery-validation/dist/jquery.validate");
require("jquery-validation/dist/localization/messages_fr");

// Select2
require("select2/dist/js/select2.full");

// Trix
global.Trix = require("trix/dist/trix");
require("../admin/config/trix");

// Toastr
global.toastr = require("toastr");
require("../admin/config/toastr");

// Lazysizes
require("lazysizes/lazysizes");
require("lazysizes/plugins/unveilhooks/ls.unveilhooks");

// Bootbox
global.bootbox = require("../vendors/bootbox/bootbox.min");

// Cleave
require("cleave.js/dist/cleave");

// Pages
require("../admin/src/shared/all");
require("../admin/src/auth/login");
require("../admin/src/leads/all");
require("../admin/src/messages/all");
require("../admin/src/alerts/all");
require("../admin/src/developments/all");
require("../admin/src/properties/all");
require("../admin/src/developers/edit");
require("../admin/src/labels/edit");
require("../admin/src/blog/all");
require("../admin/src/faq/all");
require("../admin/src/estimates/all");
require("../admin/src/places/index");
require("../admin/src/notifications/index");
