$(() => {
  if (
    $("body").hasClass("alerts") &&
    ($("body").hasClass("new") || $("body").hasClass("edit"))
  ) {
    const $form = $("form#edit-alert");
    const $submitBtn = $form.find("[type=submit]");

    $form.validate({
      ignoreTitle: true,
      rules: {
        "alert[first_name]": { required: true },
        "alert[last_name]": { required: true },
        "alert[email]": { email: true, required: true },
      },
      messages: {
        "alert[first_name]": {
          required: "Prénom obligatoire",
        },
        "alert[last_name]": {
          required: "Nom obligatoire",
        },
        "alert[email]": {
          email: "E-mail invalide",
          required: "E-mail obligatoire",
        },
      },
      errorElement: "label",
      errorPlacement: (error, element) => {
        var fg = $(element).closest(".form-group");
        $(fg).addClass("has-error");
        $(fg).append(error);
      },
      success: (label, element) => {
        var fg = $(element).closest(".form-group");
        $(fg).removeClass("has-error");
      },
      submitHandler: (form) => {
        $submitBtn.addClass("loading").attr("disabled", true);
        form.submit();
      },
      invalidHandler: (event, validator) => {
        $submitBtn.removeClass("loading").attr("disabled", false);
      },
    });

    // Places
    const $placePidsWrapper = $(".place-pids-wrapper");
    const $placePidsSelect = $placePidsWrapper.find(
      ".select2#alert_place_pids"
    );
    const placePidsSelect2 = $placePidsSelect.select2({
      minimumInputLength: 2,
      placeholder: "Saisissez une ville, un code postal",
      dropdownParent: $placePidsWrapper,
      ajax: {
        url: searchPlacesURL,
        delay: 250,
        data: (params) => {
          const query = {
            text: params.term,
          };
          return query;
        },
        processResults: (data) => {
          const hits = data.hits;
          let res = [];

          $.each(hits, (key, values) => {
            let tmp = { text: key, children: [] };
            $.each(values, (index, val) => {
              tmp["children"].push({ id: val["pid"], text: val["name"] });
            });
            res.push(tmp);
          });

          return {
            results: res,
          };
        },
      },
    });

    placePidsSelect2
      .data("select2")
      .$container.find(".select2-selection")
      .removeClass("select2-selection--single")
      .addClass("custom-select");

    // Min rooms
    let minRooms = new Cleave("#alert_min_rooms", {
      numeral: true,
      numeralDecimalMark: "",
      delimiter: "",
      numeralPositiveOnly: true,
    });

    // Min bedrooms
    let minBedrooms = new Cleave("#alert_min_bedrooms", {
      numeral: true,
      numeralDecimalMark: "",
      delimiter: "",
      numeralPositiveOnly: true,
    });

    // Min Area
    let minArea = new Cleave("#alert_min_area", {
      numeral: true,
      numeralDecimalMark: ",",
      delimiter: "",
      numeralPositiveOnly: true,
    });

    // Max Price
    let maxPrice = new Cleave("#alert_max_price", {
      numeral: true,
      numeralDecimalMark: "",
      delimiter: " ",
      numeralPositiveOnly: true,
    });

    // Status select
    const $statusSelect = $form.find(".select2#alert_status");
    const statusSelect2 = $statusSelect
      .select2({
        minimumResultsForSearch: Infinity,
        placeholder: "Choisir",
        allowClear: true,
      })
      .on("select2:unselecting", (event) => {
        $(event.currentTarget).data("unselecting", true);
      })
      .on("select2:opening", (event) => {
        const $this = $(event.currentTarget);
        if ($this.data("unselecting")) {
          $this.removeData("unselecting");
          event.preventDefault();
        }
      });

    statusSelect2
      .data("select2")
      .$container.find(".select2-selection")
      .removeClass("select2-selection--single")
      .addClass("custom-select");

    $statusSelect.on("change", (event) => {
      const $this = $(event.currentTarget);
      $this.closest(".form-group").addClass("has-error");
      $this.valid();
    });

    // Show form
    $form.removeClass("d-none");
  }
});
