import mapboxgl from "!mapbox-gl";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";

$(() => {
  if (
    $("body").hasClass("developments") &&
    ($("body").hasClass("new") || $("body").hasClass("edit-infos"))
  ) {
    const $form = $("form.edit-infos");
    let mapMarker = null;
    const $zipcode = $form.find("#development_zipcode");
    const $citySelect = $form.find(".select2#development_city_pid");

    const initForm = () => {
      const $submitBtn = $form.find("[type=submit]");

      $form.validate({
        ignoreTitle: true,
        rules: {},
        messages: {},
        errorElement: "label",
        errorPlacement: (error, element) => {
          var fg = $(element).closest(".form-group");
          $(fg).addClass("has-error");
          $(fg).append(error);
        },
        success: (label, element) => {
          var fg = $(element).closest(".form-group");
          $(fg).removeClass("has-error");
        },
        submitHandler: (form) => {
          $submitBtn.addClass("loading").attr("disabled", true);
          form.submit();
        },
        invalidHandler: (event, validator) => {
          $submitBtn.removeClass("loading").attr("disabled", false);
        },
      });

      // Developers
      const $developersSelect = $form.find(
        ".select2#development_developer_ids"
      );
      const developersSelect2 = $developersSelect
        .select2({
          minimumResultsForSearch: 0,
          multiple: true,
          placeholder: "Choisir",
          minimumInputLength: 0,
          ajax: {
            url: searchDevelopersURL,
            data: (params) => {
              const query = {
                text: params.term,
              };
              return query;
            },
            processResults: (data) => {
              return {
                results: data.hits,
              };
            },
          },
        })
        .on("select2:unselecting", (event) => {
          $(event.currentTarget).data("unselecting", true);
        })
        .on("select2:opening", (event) => {
          const $this = $(event.currentTarget);
          if ($this.data("unselecting")) {
            $this.removeData("unselecting");
            event.preventDefault();
          }
        });

      developersSelect2
        .data("select2")
        .$container.find(".select2-selection")
        .removeClass("select2-selection--single")
        .addClass("custom-select");

      // Delivery
      const $deliverySelect = $form.find(".select2#development_delivery_tmp");
      const deliverySelect2 = $deliverySelect
        .select2({
          minimumResultsForSearch: Infinity,
          placeholder: "Choisir",
          allowClear: true,
        })
        .on("select2:unselecting", (event) => {
          $(event.currentTarget).data("unselecting", true);
        })
        .on("select2:opening", (event) => {
          const $this = $(event.currentTarget);
          if ($this.data("unselecting")) {
            $this.removeData("unselecting");
            event.preventDefault();
          }
        });

      deliverySelect2
        .data("select2")
        .$container.find(".select2-selection")
        .removeClass("select2-selection--single")
        .addClass("custom-select");

      $deliverySelect.on("change", (event) => {
        const $this = $(event.currentTarget);
        $this.closest(".form-group").addClass("has-error");
        $this.valid();
      });

      // Stage
      var $stageSelect = $form.find(".select2[name='development[stage]']");
      const stageSelect2 = $stageSelect
        .select2({
          minimumResultsForSearch: Infinity,
          placeholder: "Choisir",
          allowClear: true,
        })
        .on("select2:unselecting", (event) => {
          $(event.currentTarget).data("unselecting", true);
        })
        .on("select2:opening", (event) => {
          const $this = $(event.currentTarget);
          if ($this.data("unselecting")) {
            $this.removeData("unselecting");
            event.preventDefault();
          }
        });

      stageSelect2
        .data("select2")
        .$container.find(".select2-selection")
        .removeClass("select2-selection--single")
        .addClass("custom-select");

      $stageSelect.on("change", (event) => {
        const $this = $(event.currentTarget);
        $this.closest(".form-group").addClass("has-error");
        $this.valid();
      });

      // City
      const $citySelect = $form.find(".select2[name='development[city_pid]']");
      const citySelect2 = $citySelect
        .select2({
          minimumResultsForSearch: Infinity,
          placeholder: "Choisir",
          allowClear: true,
        })
        .on("select2:unselecting", (event) => {
          $(event.currentTarget).data("unselecting", true);
        })
        .on("select2:opening", (event) => {
          const $this = $(event.currentTarget);
          if ($this.data("unselecting")) {
            $this.removeData("unselecting");
            event.preventDefault();
          }
        });

      citySelect2
        .data("select2")
        .$container.find(".select2-selection")
        .removeClass("select2-selection--single")
        .addClass("custom-select");

      $citySelect.on("change", (event) => {
        const $this = $(event.currentTarget);
        $this.closest(".form-group").addClass("has-error");
        $this.valid();
      });

      // Zipcode
      var ziptimeout;
      $zipcode.on("change", function (e) {
        var zipcode = $(this).val();
        clearTimeout(ziptimeout);
        ziptimeout = setTimeout(function () {
          feed_city(zipcode);
        }, 250);
      });

      // Latitude, Longitude
      $form.find("input#development_latitude").on("change", () => {
        updateMap();
      });

      $form.find("input#development_longitude").on("change", () => {
        updateMap();
      });

      // Fiscality
      var $fiscalitySelect = $form.find(
        ".select2[name='development[fiscality]']"
      );
      const fiscalitySelect2 = $fiscalitySelect
        .select2({
          minimumResultsForSearch: Infinity,
          placeholder: "Choisir",
          allowClear: true,
        })
        .on("select2:unselecting", (event) => {
          $(event.currentTarget).data("unselecting", true);
        })
        .on("select2:opening", (event) => {
          const $this = $(event.currentTarget);
          if ($this.data("unselecting")) {
            $this.removeData("unselecting");
            event.preventDefault();
          }
        });

      fiscalitySelect2
        .data("select2")
        .$container.find(".select2-selection")
        .removeClass("select2-selection--single")
        .addClass("custom-select");

      $fiscalitySelect.on("change", (event) => {
        const $this = $(event.currentTarget);
        $this.closest(".form-group").addClass("has-error");
        $this.valid();
        // update fiscal form
        fiscality = $fiscalitySelect.val();
        updateFiscality();
      });

      // Pinel Zone
      var $pinelZoneSelect = $form.find(
        ".select2[name='development[pinel_zone]']"
      );
      const pinelZoneSelect2 = $pinelZoneSelect
        .select2({
          minimumResultsForSearch: Infinity,
          placeholder: "Choisir",
          allowClear: true,
        })
        .on("select2:unselecting", (event) => {
          $(event.currentTarget).data("unselecting", true);
        })
        .on("select2:opening", (event) => {
          const $this = $(event.currentTarget);
          if ($this.data("unselecting")) {
            $this.removeData("unselecting");
            event.preventDefault();
          }
        });

      pinelZoneSelect2
        .data("select2")
        .$container.find(".select2-selection")
        .removeClass("select2-selection--single")
        .addClass("custom-select");

      $pinelZoneSelect.on("change", (event) => {
        const $this = $(event.currentTarget);
        $this.closest(".form-group").addClass("has-error");
        $this.valid();
      });

      // LMNP Residence Type
      var $lmnpResidenceTypeSelect = $form.find(
        ".select2[name='development[lmnp_residence_type]']"
      );
      const lmnpResidenceTypeSelect2 = $lmnpResidenceTypeSelect
        .select2({
          minimumResultsForSearch: Infinity,
          placeholder: "Choisir",
          allowClear: true,
        })
        .on("select2:unselecting", (event) => {
          $(event.currentTarget).data("unselecting", true);
        })
        .on("select2:opening", (event) => {
          const $this = $(event.currentTarget);
          if ($this.data("unselecting")) {
            $this.removeData("unselecting");
            event.preventDefault();
          }
        });

      lmnpResidenceTypeSelect2
        .data("select2")
        .$container.find(".select2-selection")
        .removeClass("select2-selection--single")
        .addClass("custom-select");

      $lmnpResidenceTypeSelect.on("change", (event) => {
        const $this = $(event.currentTarget);
        $this.closest(".form-group").addClass("has-error");
        $this.valid();
      });

      updateFiscality();

      // Show form
      $form.removeClass("d-none");
    };

    const updateFiscality = () => {
      switch (fiscality) {
        case "fnone":
          $form.find(".fiscal-group").hide();
          $form.find(".fiscal-group#accession").show();
          break;

        case "fpinel":
          $form.find(".fiscal-group").hide();
          $form.find(".fiscal-group#pinel").show();
          $form.find(".fiscal-group#accession").show();
          break;

        case "flmnp":
          $form.find(".fiscal-group").hide();
          $form.find(".fiscal-group#lmnp").show();
          break;

        default:
          $form.find(".fiscal-group").hide();
          break;
      }
    };

    var feed_city = function (zipcode) {
      if (zipcode.length == 5) {
        $citySelect.empty();
        $.ajax({
          url: searchCitiesURL,
          data: { text: zipcode },
          dataType: "json",
          success: function (data, status, xhr) {
            $.each(data.hits, function (k, v) {
              var newOption = new Option(v.name, v.pid, false, false);
              $citySelect.append(newOption);
            });
            $citySelect.trigger("change");
          },
          error: function () {},
        });
      } else {
        $citySelect.empty();
        $citySelect.trigger("change");
      }
    };

    const initMap = () => {
      mapboxgl.accessToken =
        "pk.eyJ1IjoiYWxkaW1tbyIsImEiOiJjamh2cTVxcm4wd3Q2M3Jxa3N0dDMxbThkIn0.rrCseKZtVNHzJLRUBNXy3w";

      window.map = new mapboxgl.Map({
        container: "map",
        style: "mapbox://styles/mapbox/streets-v11",
        center: [2.6187, 47.8249],
        zoom: 4,
        interactive: true,
        scrollZoom: false,
      });

      var nav = new mapboxgl.NavigationControl();
      map.addControl(nav, "top-right");

      map.on("load", (e) => {
        map.resize();
      });
    };

    const initGeocoder = () => {
      var geocoder = new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        types: "address",
      });

      geocoder.addTo("#mapbox-geocoder-wrapper");

      geocoder.on("result", (e) => {
        const result = e.result;

        $form.find("input#development_address").val(result["place_name"]);

        for (var k in result.context) {
          const { id, text } = result.context[k];
          const tg = id.split(".")[0];

          switch (tg) {
            case "postcode":
              $form
                .find("input#development_zipcode")
                .val(text)
                .trigger("change");
              break;

            default:
              break;
          }
        }

        const lat = result.geometry.coordinates[1];
        const lng = result.geometry.coordinates[0];

        $form.find("input#development_latitude").val(lat);
        $form.find("input#development_longitude").val(lng);

        $form.find("input#development_address").valid();
        $form.find("input#development_zipcode").valid();
        $form.find("input#development_latitude").valid();
        $form.find("input#development_longitude").valid();

        updateMap();
      });
    };

    const updateMap = () => {
      const lat = $form.find("input#development_latitude").val();
      const lng = $form.find("input#development_longitude").val();

      if ((lat != "") & (lng != "")) {
        if (mapMarker) {
          mapMarker.remove();
        }

        var ll = new mapboxgl.LngLat(lng, lat);
        mapMarker = new mapboxgl.Marker().setLngLat(ll).addTo(map);

        map.flyTo({ center: [lng, lat], zoom: 15 });
      }
    };

    initForm();
    initMap();
    initGeocoder();
    updateMap();
  }
});
