$(() => {
  if ($("body").hasClass("properties") && $("body").hasClass("edit-admin")) {
    // Form
    const $form = $("form#edit-admin");
    const $submitBtn = $form.find("[type=submit]");

    $form.validate({
      ignoreTitle: true,
      rules: {},
      messages: {},
      errorElement: "label",
      errorPlacement: (error, element) => {
        var fg = $(element).closest(".form-group");
        $(fg).addClass("has-error");
        $(fg).append(error);
      },
      success: (label, element) => {
        var fg = $(element).closest(".form-group");
        $(fg).removeClass("has-error");
      },
      submitHandler: (form) => {
        $submitBtn.addClass("loading").attr("disabled", true);
        form.submit();
      },
      invalidHandler: (event, validator) => {
        $submitBtn.removeClass("loading").attr("disabled", false);
      },
    });

    // Status
    const $statusSelect = $form.find(".select2#property_status");
    const statusSelect2 = $statusSelect
      .select2({
        minimumResultsForSearch: Infinity,
        placeholder: "Choisir",
        allowClear: true,
      })
      .on("select2:unselecting", (event) => {
        $(event.currentTarget).data("unselecting", true);
      })
      .on("select2:opening", (event) => {
        const $this = $(event.currentTarget);
        if ($this.data("unselecting")) {
          $this.removeData("unselecting");
          event.preventDefault();
        }
      });

    statusSelect2
      .data("select2")
      .$container.find(".select2-selection")
      .removeClass("select2-selection--single")
      .addClass("custom-select");

    $statusSelect.on("change", (event) => {
      const $this = $(event.currentTarget);
      $this.closest(".form-group").addClass("has-error");
      $this.valid();
    });
  }
});
