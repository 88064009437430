import ActiveStorageUpload from "@excid3/uppy-activestorage-upload";
import Uppy from "@uppy/core";
import Dashboard from "@uppy/dashboard";

$(() => {
  if (
    $("body").hasClass("labels") &&
    ($("body").hasClass("new") || $("body").hasClass("edit"))
  ) {
    const $form = $("form#edit-label");
    const $submitBtn = $form.find("[type=submit]");

    $form.validate({
      ignoreTitle: true,
      rules: {
        "label[name]": { required: true },
        "label[description]": { required: true },
      },
      messages: {
        "label[name]": {
          required: "Nom obligatoire",
        },
        "label[description]": {
          required: "Description obligatoire",
        },
      },
      errorElement: "label",
      errorPlacement: (error, element) => {
        var fg = $(element).closest(".form-group");
        $(fg).addClass("has-error");
        $(fg).append(error);
      },
      success: (label, element) => {
        var fg = $(element).closest(".form-group");
        $(fg).removeClass("has-error");
      },
      submitHandler: (form) => {
        $submitBtn.addClass("loading").attr("disabled", true);
        form.submit();
      },
      invalidHandler: (event, validator) => {
        $submitBtn.removeClass("loading").attr("disabled", false);
      },
    });

    // Upload logo
    const $uppyWrapper = $("[data-uppy]");

    const setUpUppy = () => {
      let trigger = document.querySelector("[data-behavior='uppy-trigger']");
      let directUploadUrl = $("meta[name='direct-upload-url']").attr("content");
      let fieldName = $uppyWrapper.data("uppy");

      trigger.addEventListener("click", (e) => {
        e.preventDefault();
      });

      let uppy = Uppy({
        autoProceed: true,
        allowMultipleUploads: false,
        logger: Uppy.debugLogger,
      });

      uppy.use(ActiveStorageUpload, {
        directUploadUrl: directUploadUrl,
      });

      uppy.use(Dashboard, {
        trigger: trigger,
        closeAfterFinish: false,
      });

      uppy.on("complete", (result) => {
        $("[data-pending-upload]").remove();

        result.successful.forEach((file) => {
          setUploadedFile(file, fieldName);
        });
      });
    };

    const setUploadedFile = (file, fieldName) => {
      $uppyWrapper
        .find(".logoImg")
        .attr("src", `${cloudFrontURL}/${file.response.key}`);
      $uppyWrapper.find(".logoKey").val(file.response.key);
    };

    setUpUppy();

    // Show form
    $form.removeClass("d-none");
  }
});
